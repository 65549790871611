cs.ns('app.ui.root')
app.ui.root.ctrl = cs.clazz({
    extend:   app.fw.root.ctrl,
    mixin:    [
        app.ui.trait.root.businessservices.ctrl,
        app.ui.trait.root.appservices.ctrl,
        app.ui.trait.root.hash.ctrl,
        app.ui.trait.root.websocketservices.ctrl,
        app.ui.trait.modaldialogs.ctrl
    ],
    dynamics: {
        hideTimer:   null,
        hideTimeout: 10000
    },
    protos:   {

        create () {
            this.base(app.ui.root.model, app.ui.root.view)
            cs(this).property('ComponentJS:state-auto-increase', true)

            this.content = this.view.create('content', app.ui.composite.content.ctrl)
            this.osc     = this.view.create('osc', app.ui.composite.osc.ctrl)
        },

        setup () {
            this.base()

            const lang = this.userLanguage()
            moment.locale((lang == "en" || lang == "de") ? lang : "de")

            //
            //    M A R K U P   L O A D I N G
            //
            cs(this).guard('prepare', +1)
            try {
                $(document).ready(() => {
                    $.markup.load(() => {
                        cs(this).guard('prepare', -1)
                    })
                })
            } catch (e) {
                cs(this).guard('prepare', -1)
            }
        },

        prepare () {
            this.base()

            this.observeOwnModel('event:showOSC', () => {
                this.enableHideTimer()
                this.model.value('state:OSCVisible', true)
            })

            this.observeOwnModel('event:keepOSCVisible', () => {
                this.enableHideTimer()
            })

            this.subscribeForChildEvent('webSocketConnected', () => {

            })

            this.subscribeForChildEvent('webSocketDisconnected', () => {

            })
        },

        render() {
            this.base()

            this.enableHideTimer()
        },

        initialize (options) {
            this.model.value('global:state:autoplay', options.autoplay === true || this.model.value('global:state:embedded'))
            this.model.value('state:OSCVisible', options.autoplay !== true || this.model.value('global:state:embedded'))
        },

        clearHideTimer() {
            if (this.hideTimer)
                window.clearTimeout(this.hideTimer)
        },

        enableHideTimer() {
            this.clearHideTimer()
            const channel = this.model.value('global:data:selectedChannel')
            if (channel && channel.hash) {
                this.hideTimer = window.setTimeout(() => {
                    this.model.value('state:OSCVisible', false)
                    this.clearHideTimer()
                }, this.hideTimeout)
            }
        },

        userLanguage() {
            return this.model.value('global:data:userLanguage')
        },

        hasError (responseObj) {
            return responseObj &&
                Object.prototype.toString.call(responseObj) === Object.prototype.toString.call({}) &&
                responseObj.messageId
        }

    }
})